"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setStoreFromParams = void 0;
const rechnungenStore_1 = require("@/state/rechnungenStore");
const teamsStore_1 = require("@/state/teamsStore");
const types_1 = require("../../../types");
function setStoreFromParams(routeParams) {
    if (routeParams) {
        let newParams = Object.assign({}, routeParams);
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.leistungserbringer) {
            rechnungenStore_1.rechnungenStore.commit.setLeistungserbringerSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.leistungserbringer));
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.team) {
            rechnungenStore_1.rechnungenStore.commit.setTeamSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.team));
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.status) {
            rechnungenStore_1.rechnungenStore.commit.setStatusFiter(routeParams.status);
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.bagatellschwelle) {
            rechnungenStore_1.rechnungenStore.commit.setBagatellschwelle(routeParams.bagatellschwelle);
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.erstelltFrom) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.erstelltTo)) {
            rechnungenStore_1.rechnungenStore.commit.setDate({
                startDate: routeParams.erstelltFrom,
                endDate: routeParams.erstelltTo,
            });
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.faelligFrom) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.faelligTo)) {
            newParams = setAlleStatus(newParams);
            rechnungenStore_1.rechnungenStore.commit.setDate({
                startDate: routeParams.faelligFrom,
                endDate: routeParams.faelligTo,
            });
        }
        return newParams;
    }
}
exports.setStoreFromParams = setStoreFromParams;
function setAlleStatus(newParams) {
    rechnungenStore_1.rechnungenStore.commit.setStatusFiter(types_1.RechnungSearchStatusFilter.Alle);
    newParams.status = types_1.RechnungSearchStatusFilter.Alle;
    return newParams;
}
